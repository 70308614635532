<template>
    <v-radio-group :rules="elements[0].mandatory ? rules : []" :error="error" v-model="radioGroup">
        <v-radio v-for="(element, index) in elements" :key="index + element.id" :label="element.text" :value="element.text"></v-radio>
    </v-radio-group>
</template>
  
<script>
export default {
    props: {
        elements: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            error: false,
            radioGroup: this.elements.find(element => element.answer)?.text || null
        }
    },
    watch: {
        radioGroup() {
            this.setValues();
        }
    },
    computed: {
        rules() {
            return [
                v => !!v || this.$t("signer_form.required_field")
            ];
        }
    },
    methods: {
        setValues() {
            let answeredElements = [...this.elements];

            answeredElements.forEach((element, index) => {
                answeredElements[index].answer = (element.text === this.radioGroup);
            });

            this.emitAnswerUpdate(answeredElements);
        },
        emitAnswerUpdate(answeredElements) {
            this.$emit('answer-updated', answeredElements, true);
        },
        validateField() {
            this.error = false;

            if (this.elements[0].mandatory) {
                if (!this.radioGroup) {
                    this.error = true;
                    return false;
                }
            }

            return true;
        }
    },
};
</script>
