<template>
	<v-text-field  :label="element.text" outlined dense clearable :rules="element.mandatory ? rules : []"
		v-model="element.answer" @input="emitAnswerUpdate" :error="error"></v-text-field>
</template>
<script>
export default {
	props: {
		element: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			error: false
		}
	},
	computed: {
		rules() {
			return [
				v => !!v || this.$t("signer_form.required_field")
			];
		},
	},
	methods: {
		emitAnswerUpdate() {
			this.$emit('answer-updated', this.element)
		},
		validateField() {
			this.error = false;

			if (this.element.mandatory) {
				if (this.element.answer === '' || this.element.answer === null) {
					this.error = true
				}
				return this.element.answer !== '' && this.element.answer !== null
			}

			return true
		}
	}
};
</script>