<template>
	<v-checkbox hide-details="auto" v-model="element.answer" :label="element.text" :rules="element.mandatory ? rules : []"
		@change="emitAnswerUpdate" :error="error" class="custom-checkbox"></v-checkbox>
</template>

<script>
export default {
props: {
	element: {
		type: Object,
		required: true
	}
},
data() {
	return {
		error: false
	}
},
computed: {
	rules() {
		return [
			v => !!v || this.$t("signer_form.required_field")
		];
	},

},
methods: {
	emitAnswerUpdate() {
		if (this.element.answer === null || this.element.answer === undefined) {
            this.element.answer = false;
    }
        this.$emit('answer-updated', this.element)
	},
	validateField() {
		if (this.element.mandatory) {
			if (!this.element.answer) {
				this.error = true
			}
			return !!this.element.answer;
		}
		return true
	}
},

};
</script>